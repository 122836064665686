import { setLocale, setCountryCode } from '@adobe-fonts/site/lib/I18N.js';

/**
 * Switch the locale used by the web components i18n code.
 *
 * This will also load the associated localizations files for each of the
 * components we're using.
 *
 * Localization file loading takes advantage of webpack's dynamic module loading
 * and chunking. At build time, webpack will chunk together all translations
 * files for each locale. For example, all of the es-ES files will be chunked
 * together.
 *
 * When the locale switches, only the JSON file for the current locale will be
 * dynamically imported.
 */
async function switchLocale(locale) {
  const addToWebProjectButtonMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/add-to-web-project-button/i18n/${locale}.json`);

  const ccxSpecimenCardMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/ccx/ccx-specimen-card/i18n/${locale}.json`);

  const feedbackButtonMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/feedback-button/i18n/${locale}.json`);

  const navbarHeaderMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/navbar-header/i18n/${locale}.json`);

  const navbarSubnavMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/navbar-subnav/i18n/${locale}.json`);

  const searchbarMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/search-bar/i18n/${locale}.json`);

  const sideNavMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/side-navigation/i18n/${locale}.json`);

  const specimenCardMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/specimen-card/i18n/${locale}.json`);

  setLocale(locale, {
    ...addToWebProjectButtonMessages,
    ...ccxSpecimenCardMessages,
    ...feedbackButtonMessages,
    ...navbarHeaderMessages,
    ...navbarSubnavMessages,
    ...searchbarMessages,
    ...sideNavMessages,
    ...specimenCardMessages
  });
}

/**
 * Once the DOM has loaded, make sure i18n is properly initialized.
 */
function initI18n() {
  const localInfoTag = document.getElementById('/locale_info');
  if (localInfoTag) {
    const localInfo = JSON.parse(localInfoTag.innerText);
    if (localInfo.selectedLocale) {
      setCountryCode(localInfo.countryCode);
      switchLocale(localInfo.selectedLocale.replace('_', '-'));
    }
  }
}

window.addEventListener('DOMContentLoaded', initI18n);
