var FAILED_CLASS = 'display-in-font--failed';
var LOADED_CLASS = 'display-in-font--loaded';
var LOADING_CLASS = 'display-in-font--loading';

var ADOBE_NOTDEF_FAMILY = 'adobe-notdef';

/**
 * A directive that will load in a font and display the child element in that font.
 *
 * While the font is loading, the directive will attach a loading class to the
 * container element. Once the font has loaded (or failed to load) the directive
 * will remove the loading class and attach either a loaded or a failed class.
 *
 * @example
 *
 *  <div display-in-font="parent.getDisplayFont()">
 *    Some text
 *  </div>
 *
 * @ngInject
 */
function Directive($timeout, FontLoaderService) {
  var TIMEOUT_DELAY_MS = 10;

  function loadAndSetFont(scope, el) {
    // Gracefully handle situations where the data passed to display-in-font is
    // empty.
    var font = scope.displayInFont();
    if (!font) {
      return;
    }

    el.addClass(LOADING_CLASS);

    // TODO: Remove usage of FVD, and pass around a descriptors object.
    var weight = font.fvd[1] + '00';
    var style = (font.fvd[0] === 'n' ? 'normal' : (font.fvd[0] === 'i' ? 'italic' : 'oblique'));

    // The font-family for this element will match up with the font family
    // loaded in by FontLoader.
    el[0].style.fontFamily = [
      // The font-family for this element will match up with the font family
      // loaded in by FontLoader.
      font.cssName,
      // Always add adobe-notdef to the end of the font stack as a fallback.
      ADOBE_NOTDEF_FAMILY
    ].join(',');
    el[0].style.fontWeight = weight;
    el[0].style.fontStyle = style;

    // Force the font load to happen in a timeout to help minimize the
    // interaction between the font loading and Angular rendering the content
    // (the interaction can be particularly bad on elements with animations).
    $timeout(function() {
      var descriptors = {
        weight: weight,
        style: style,
        featureSettings: font.featureSettings,
        unicodeRange: font.unicodeRange,
        dynamic: font.isDynamic,
        display: 'block'
      };

      FontLoaderService.loadFont(
        font.cssName,
        font.opaqueId,
        descriptors).
      then(function() {
        el.removeClass(LOADING_CLASS);
        el.addClass(LOADED_CLASS);
      }, function() {
        el.removeClass(LOADING_CLASS);
        el.addClass(FAILED_CLASS);
      });
    }, TIMEOUT_DELAY_MS);
  }

  return {
    restrict: 'A',

    scope: {
      displayInFont: '&'
    },

    link: function(scope, el) {
      // Monitor for any changes that occur to the currently displayed font
      scope.$watch('displayInFont()', loadAndSetFont.bind(this, scope, el), true);
    }
  };
}

module.exports = Directive;
